@mixin ele-font($name) {
  font-family: $name !important;
}

.border-gray {
  border: 1px solid #a8a29e !important;
}

.background-transparent {
  background: transparent !important;
}

@for $i from 0 through 1000 {
  .h-#{$i} {
    height: $i * 1px !important;
  }

  .w-#{$i} {
    width: $i * 1px !important;
  }
}

@for $i from 0 through 100 {
  .hp-#{$i} {
    height: $i * 1% !important;
  }

  .wp-#{$i} {
    width: $i * 1% !important;
  }
}

@for $i from 0 through 100 {
  .border-radius-#{$i} {
    border-radius: $i * 1px !important;
  }

  .fs-#{$i} {
    font-size: $i * 1px !important;

    &.light-text {
      @include ele-font("muli-light");
    }

    &.regular-text {
      @include ele-font("muli-regular");
    }

    &.medium-text {
      @include ele-font("muli-medium");
    }

    &.demi-bold-text {
      @include ele-font("muli-semi-bold");
    }

    &.bold-text {
      @include ele-font("muli-bold");
    }
  }

  .pa-#{$i} {
    padding: $i * 1px !important;
  }

  .pr-#{$i} {
    padding-right: $i * 1px !important;
  }

  .pl-#{$i} {
    padding-left: $i * 1px !important;
  }

  .pt-#{$i} {
    padding-top: $i * 1px !important;
  }

  .pb-#{$i} {
    padding-bottom: $i * 1px !important;
  }

  .plr-#{$i} {
    padding-left: $i * 1px !important;
    padding-right: $i * 1px !important;
  }

  .prl-#{$i} {
    padding-top: $i * 1px !important;
    padding-bottom: $i * 1px !important;
  }

  .ptb-#{$i} {
    padding-top: $i * 1px !important;
    padding-bottom: $i * 1px !important;
  }

  .pbt-#{$i} {
    padding-top: $i * 1px !important;
    padding-bottom: $i * 1px !important;
  }

  .ma-#{$i} {
    margin: $i * 1px !important;
  }

  .mr-#{$i} {
    margin-right: $i * 1px !important;
  }

  .mt-#{$i} {
    margin-top: $i * 1px !important;
  }

  .mb-#{$i} {
    margin-bottom: $i * 1px !important;
  }

  .ml-#{$i} {
    margin-left: $i * 1px !important;
  }

  .mlr-#{$i} {
    margin-left: $i * 1px !important;
    margin-right: $i * 1px !important;
  }

  .mrl-#{$i} {
    margin-left: $i * 1px !important;
    margin-right: $i * 1px !important;
  }

  .mtb-#{$i} {
    margin-top: $i * 1px !important;
    margin-bottom: $i * 1px !important;
  }

  .mbt-#{$i} {
    margin-top: $i * 1px !important;
    margin-bottom: $i * 1px !important;
  }
}

.mlr-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.ml-auto {
  margin-left: auto !important;
}

.text-center {
  text-align: center !important;
}

.pos-relative {
  position: relative;
}

.text-color-gray {
  color: #6a727a !important;
}

.headline {
  font-size: 20px;
  line-height: 32px;
  font-weight: 600;
  letter-spacing: normal;
}

.pull-right {
  float: right;
}

.cursor-not-allowed {
  cursor: not-allowed !important;
}

.cursor-pointer {
  cursor: pointer !important;
}

.border-none {
  border: 0 !important;
}

.vert-middle {
  vertical-align: middle;
}

.flex {
  display: flex;

  &.center {
    align-items: center;
  }
}

.flex-x {
  display: flex;
  flex-direction: row;

  &.align-center {
    align-items: center;
  }

  &.center {
    justify-content: center;
  }
}

.flex-y {
  display: flex;
  flex-direction: column;

  &.align-center {
    align-items: center;
  }

  &.center {
    justify-content: center;
  }
}

.space-between {
  justify-content: space-between;
}

.justify-center {
  justify-content: center;
}

.align-center {
  align-items: center;
}

.flex-0 {
  flex: 0;
}

.flex-1 {
  flex: 1;
  min-width: 0;
  min-height: 0;
}

.flex-wrap {
  flex-wrap: wrap;
}

.full-height {
  height: 100vh;
}

.fill-width {
  width: 100% !important;
}

.fill-height {
  height: 100% !important;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hide {
  overflow: hidden;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-underline {
  &:hover {
    text-decoration: underline;
  }
}

.with-transition {
  transition: all 0.3s ease-in;
}

.hover-border {
  &:hover {
    text-decoration: underline;
  }
}

.align-base {
  align-items: baseline;
}

.Image-Grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(320px, 320px));
  grid-gap: 10px 10px;
  grid-auto-rows: 180px;
}

.deleteImagebtn {
  .button-style {
    display: none;
  }
}

.deleteImagebtn:hover {
  .button-style {
    display: block;
    position: absolute;
    top: 6px;
    right: 10px;
    cursor: pointer;
  }
}

.divDisabled {
  pointer-events: none;
  opacity: 0.5;
}

.starRating {
  color: #fde047 !important;
  padding-inline: 6px !important;
}

.breakWord {
  word-wrap: break-word;
}

.storeKey {
  border: 1px solid #94a3b8;
  padding-block: 6px;
  padding-inline: 16px;
  border-radius: 8px;
  background-color: #f1f5f9;
  width: 100%;
}

.keyString {
  overflow-wrap: anywhere !important;
  white-space: pre-wrap !important;
}

.border-primaryGreen {
  border: 1px solid #4cbb17;
}

.color-white {
  color: #fff !important;
}

.bg-primaryGreen {
  background-color: #4cbb17;
}

.object-cover {
  object-fit: cover;
}
